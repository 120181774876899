
export default {
  name: "SwiperOpinions",

  data() {
    return {
      swiperOption: {
        loop: true,
        freeMode: true,
        spaceBetween: 20,
        grabCursor: true,
        slidesPerView: 1,
        autoplay: false,
        speed: 1000,
        freeModeMomentum: false,
        coverflowEffect: {
          depth: 100,
          modifier: 1,
          rotate: 5,
          stretch: 0,
        },
        freeModeMomentumBounce: false,
        freeModeMomentumRatio: 0.1,
        freeModeMomentumVelocityRatio: 0.8,
        freeModeSticky: true,
        navigation: {
          nextEl: ".swiper-button-next-custom",
          prevEl: ".swiper-button-prev-custom",
        },
      },

      slides: [
        {
          masses:
            "هذا ثاني متجر وتطبيق سويناه معهم والحمدلله ينافس المواقع المشهورة من حيث التصميم وسرعة التصفح ونطمح للافضل وشكرا لتعاونكم الدائم معنا ودمتم بخير.",
          fullName: "عبدالله محمد الوصابي",
          info: "عبايات اللمسة المميزة",
          pic: "/assets/images/flags/Flags-1.png",
        },
        {
          masses:
            "فريق الدعم الفني أولًا، فريق رائع بشتى النواحي .. خدمة راقيه و مميزة.",
          fullName: "احمد علي",
          info: "متجر دار حروف",
          pic: "/assets/images/flags/Flags-1.png",
        },
        {
          masses:
            "نشكر منصة متجرة على الدعم مستمر بالمتجر والتجاوب مع اي استفسار ، رد ودعم سريع ومنصة ممتازة جدا وسهولة للتواصل مع خدمة العملاء كلمة شكر لا تكفي متجرة.",
          fullName: "محمد خليل",
          info: "متجر بيت الأطفال",
          pic: "/assets/images/flags/Flags-2.png",
        },
        {
          masses:
            "من غير مجامله وشهادة حق وبكل أمانة فريق متجرة متعاونين جداً ومتجاوبين وكنتم أحد اعمدة النجاح بالنسبة لنا ،",
          fullName: "عبدالله محمد الوصابي",
          info: "عبايات اللمسة الجميلة",
          pic: "/assets/images/flags/Flags-2.png",
        },
        {
          masses:
            "منصة جميلة و سهلة وواجهات بسيطة . و ما شاء الله عليكم اي مشكلة تنحل باسرع وقت . فيكم الخير و البركة٫",
          fullName: "تركي الزهراني",
          info: "متجر أسماك الزينة",
          pic: "/assets/images/flags/Flags-2.png",
        },
        {
          masses:
            "ممتنه لهم و على خدماتهم شركاء نجاحي .. متعاونين و خلوقين.. تكفيني امانتهن و اسلوبهم الراقي.. و حرصهم على اعلاني بكل جديد يحسن من الموقع.. شكراً لكم",
          fullName: "وعد",
          info: "دودي شوب",
          pic: "/assets/images/flags/Flags-2.png",
        },
        {
          masses:
            "فخور بإكمال عامي الأول وبكل صدق بالبداية كنت شايم هم الموقع والربط مع الجهات الأخرى لكن هم سوو لي كل شيء ، بكل فخر أوصي بهم ",
          fullName: "وليد",
          info: "متجر ليتل روزا",
          pic: "/assets/images/flags/Flags-1.png",
        },
      ],
    };
  },
  mounted() {
    if (window.innerWidth > 960) {
      this.swiperOption.autoplay = { delay: 5000 };
    }
  },
};
